<template>
  <div>
    <table-list
      :fields="fields"
      module="products"
      :show-search="false"
    />
  </div>
</template>

<script>
import TableList from '@/views/components/TableList.vue'
import store from '@/store'
import { computed, onUnmounted } from '@vue/composition-api'
import productStoreModule from './productStoreModule'

export default {
  components: {
    TableList,
  },
  setup() {
    const PRODUCT_STORE_MODULE_NAME = 'products'

    // Register module
    if (!store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.registerModule(PRODUCT_STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_STORE_MODULE_NAME)
    })

    const fields = computed(() => store.state.products.tableFields)

    return {
      fields,
    }
  },
}
</script>

<style>

</style>
