<template>
  <b-sidebar
    id="table-list-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    :sidebar-class="sidebarClass"
    shadow
    backdrop
    no-header
    right
    @hidden="resetSidebar"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ title }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <component
            :is="sidebarComponent"
            v-if="sidebarComponent"
            ref="sidebarComponent"
            :is-sidebar-active.sync="isSidebarActive"
            :data="data"
            @refetch-data="$emit('refetch-data')"
            @start-data="setStartData"
          />

          <div class="d-flex mt-2 px-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <span v-if="currentItem === null">Add</span>
              <span v-else>Update</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BButton, BForm,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import { ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import Api from '@/libs/api'

export default {
  components: {
    BSidebar,
    BButton,
    BForm,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    sidebarComponent: {
      type: Function,
      required: false,
      default: () => {},
    },
    sidebarClass: {
      type: String,
      required: false,
      default: 'sidebar-xl',
    },
    module: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const currentItem = ref(null)
    const startData = ref({})
    const data = ref({})
    const resetData = () => {
      data.value = JSON.parse(JSON.stringify(startData))
    }

    const loadData = id => {
      Api.get(props.module, id)
        .then(response => {
          data.value = JSON.parse(JSON.stringify(response))
          emit('update:is-sidebar-active', true)
          currentItem.value = id
        })
    }

    const onSubmit = () => {
      if (currentItem.value === null) {
        Api.post(props.module, data.value)
          .then(() => {
            emit('refetch-data')
            emit('update:is-sidebar-active', false)
          })
      } else {
        Api.update(props.module, currentItem.value, data.value)
          .then(() => {
            emit('refetch-data')
            emit('update:is-sidebar-active', false)
          })
      }
    }

    const {
      refFormObserver,
      resetForm,
    } = formValidation(resetData)

    return {
      data,
      onSubmit,

      refFormObserver,
      resetForm,
      startData,

      loadData,
      currentItem,
    }
  },
  methods: {
    setStartData(data) {
      this.startData = data
      this.data = data
    },
    resetSidebar() {
      this.currentItem = null
      this.resetForm()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
