import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Api from '@/libs/api'

export default function useTableList(props) {
  const toast = useToast()

  const refListTable = ref(null)
  const searchQuery = ref('')
  const totalRows = ref(0)
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref(props.sortBy)
  const isSortDirDesc = ref(false)
  const isSidebarActive = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
    }
  })

  const fetchData = (ctx, callback) => {
    const payload = {
      itemsPerPage: perPage.value,
      page: currentPage.value,
      search_query: [searchQuery.value],
    }
    if (sortBy.value) {
      payload[`order[${sortBy.value}]`] = isSortDirDesc.value ? 'desc' : 'asc'
    }

    Api.fetch(props.module, payload)
      .then(data => {
        callback(data['hydra:member'])
        totalRows.value = data['hydra:totalItems']
      })
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  return {
    fetchData,
    refetchData,
    currentPage,
    perPage,
    searchQuery,
    perPageOptions,
    totalRows,
    dataMeta,
    refListTable,
    sortBy,
    isSortDirDesc,
    isSidebarActive,
  }
}
