import axios from '@axios'

export default {
  namespaced: true,
  state: {
    tableFields: [
      { key: 'id' },
      { key: 'name', sortable: true },
    ],
  },
  getters: {},
  mutations: {},
  actions: {
  },
}
