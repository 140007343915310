<template>
  <div>
    <table-list-sidebar
      v-if="sidebarComponent"
      ref="tableListSidebar"
      :sidebar-component="sidebarComponent"
      :is-sidebar-active.sync="isSidebarActive"
      :title="sidebarTitle"
      :module="module"
      @refetch-data="refetchData"
    />

    <b-card
      no-body
      class="mb-0"
    >
      <div
        v-if="showSearch || showEntriesChoice"
        class="m-2"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div v-if="showEntriesChoice">
              <label>Show</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </div>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-if="showSearch === true"
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Search..."
              />
              <b-button
                v-if="sidebarButton"
                variant="primary"
                class="ml-1"
                @click="isSidebarActive = true"
              >
                <span class="text-nowrap">{{ sidebarButton }}</span>
              </b-button>
              <slot name="extra-buttons" />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        striped
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        @row-clicked="rowClicked"
      >
        <template
          v-for="slot in Object.keys($scopedSlots)"
          v-slot:[toCellName(slot)]="props"
        >
          <slot
            v-bind="props"
            :name="slot"
          />
        </template>
      </b-table>
      <div
        v-if="showPagination"
        class="mx-2 mb-2"
      >
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ totalRows }} entries</span>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BPagination, BTable, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TableListSidebar from '@/views/components/TableListSidebar.vue'
import useTableList from './useTableList'

export default {
  components: {
    TableListSidebar,
    BRow,
    BCol,
    BCard,
    BPagination,
    BTable,
    BFormInput,
    BButton,
    vSelect,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    sortBy: {
      type: String,
      required: false,
      default: '',
    },
    module: {
      type: String,
      required: true,
    },
    sidebarComponent: {
      type: Function,
      required: false,
      default: () => {},
    },
    sidebarButton: {
      type: String,
      required: false,
      default: '',
    },
    sidebarTitle: {
      type: String,
      required: false,
      default: '',
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: true,
    },
    showEntriesChoice: {
      type: Boolean,
      required: false,
      default: true,
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const {
      fetchData,
      refetchData,
      searchQuery,
      perPage,
      currentPage,
      perPageOptions,
      totalRows,
      dataMeta,
      refListTable,
      isSortDirDesc,
      isSidebarActive,
    } = useTableList(props)

    return {
      fetchData,
      refetchData,
      searchQuery,
      perPage,
      currentPage,
      perPageOptions,
      totalRows,
      dataMeta,
      refListTable,
      isSortDirDesc,
      isSidebarActive,
    }
  },
  methods: {
    toCellName(slot) {
      if (slot === 'row-details') return slot
      return `cell(${slot})`
    },
    rowClicked(item) {
      // eslint-disable-next-line no-param-reassign,no-underscore-dangle
      if (item._showDetails) item._showDetails = false
      else this.$set(item, '_showDetails', true)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
